import React, { useState, useEffect,useContext } from 'react';
import profileService from '../services/profile.service';
import { AuthContext } from '../context/authContext';


const HelpBox = ({ isAdmin }) => {
  const [requests, setRequests] = useState([]);
  const [newRequest, setNewRequest] = useState('');
  const [response, setResponse] = useState('');
  const { currentUser } = useContext(AuthContext);


  useEffect(() => {
    getRequest();
  }, []);

  const getRequest = async (id) => {
    try {
      const res = await profileService.getResult({
        qry: `CALL sp_support('${currentUser?.id}','check','${id}',0)`,
      }).then((r) => {
        setRequests(r?.[0]);
      });
    } catch (error) {
      console.error('Error fetching requests:', error);
    }
  };

  const submitRequest = async () => {
    try {
      await profileService.getResult({
        qry: `CALL sp_support('${currentUser?.id}','newRequest','${newRequest}',0)`,
      });
      setNewRequest('');
      getRequest();
    } catch (error) {
      console.error('Error submitting request:', error);
    }
  };

  const submitResponse = async (id) => {
    try {
      await profileService.getResult({
        qry: `CALL sp_support('${currentUser?.id}','response','${id}','${response}')`,
      });
      setResponse('');
      getRequest();
    } catch (error) {
      console.error('Error submitting response:', error);
    }
  };

  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom"></div>
    <div className="container py-4">
      <h1 className="mb-4">HelpBox</h1>

      {!isAdmin && (
        <div className="mb-4">
          <h5>Submit a Request</h5>
          <textarea
            className="form-control mb-3"
            rows="3"
            value={newRequest}
            onChange={(e) => setNewRequest(e.target.value)}
            placeholder="Write your request here..."
          />
          <button className="btn btn-primary" onClick={submitRequest}>
            Submit Request
          </button>
        </div>
      )}

      <h2 className="mt-4">{isAdmin ? 'All Requests' : 'My Requests'}</h2>
      <div className="row">
        {requests.map((req) => (
          <div key={req.id} className="col-md-6 mb-4">
            <div className="card shadow-sm">
              <div className="card-body">
                <p className="card-text">
                  <strong>Request:</strong> {req.text}
                </p>
                <p className="card-text">
                  <strong>Status:</strong> {req.status}
                </p>
                <p className="card-text">
                  <strong>Response:</strong> {req.response || 'Pending...'}
                </p>

                {isAdmin && !req.response && (
                  <div>
                    <textarea
                      className="form-control mb-2"
                      rows="2"
                      value={response}
                      onChange={(e) => setResponse(e.target.value)}
                      placeholder="Write your response here..."
                    />
                    <button
                      className="btn btn-success"
                      onClick={() => submitResponse(req.id)}
                    >
                      Submit Response
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default HelpBox;
