// import Demo from "./demo/Demo";
import PostComments from "./pages/PostComments"
import Income from "./pages/Income"
import Home from "./pages/Home";
//import Nominations from "./pages/Nominations";
import Nominate from "./pages/Nominate";
import Participants from "./pages/Participants";
import Terms from "./pages/Terms";
import Community from "./pages/Community";
import Badge from "./pages/Badge";
import GupShup from "./pages/GupShup";
import FreeStyle from "./pages/FreeStyle";

import Member from "./pages/Member";
import Judge from "./pages/Judge";
// import Email from "./pages/Email";
import Denied from "./pages/Denied";
import Settings from "./pages/Settings";
import Account from "./pages/Account";
// import Contactinfo from "./pages/Contactinfo";
// import Socialaccount from "./pages/Socialaccount";
// import Password from "./pages/Password";
// import Payment from "./pages/Payment";
import Notification from "./pages/Notification";
// import Helpbox from "./pages/Helpbox";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Forgot from "./pages/Forgot";
import Notfound from "./pages/Notfound";
import Moments from "./pages/Moments";
import ChatInbox from "./pages/ChatInbox";
import Start from "./pages/Start";
import Composition from "./pages/Composition";
import Sponsor from "./pages/Sponsor";
import VoiceChatRoom from "./pages/VoiceChatRoom";
import Chat from "./pages/Chat";
// import Live from "./pages/Live";
// import Job from "./pages/Job";
// import Event from "./pages/Event";
// import Hotel from "./pages/Hotel";
// import Videos from "./pages/Videos";
import Comingsoon from "./pages/Comingsoon";
import BoatRace from "./pages/BoatRace";
// import Grouppage from "./pages/Grouppage";
import Userpage from "./pages/Userpage";
// import Authorpage from "./pages/Authorpage";
// import Hotelsingle from "./pages/Hotelsingle";

import PostsProvider from "./context/postsContext";
import EventProvider from "./context/eventContext";
import SocketContextProvider from "./context/chatContext";
import Events from "./pages/Events";
import Certificate from "./pages/Certificate";
import Grps from "./pages/Group";
import Audition from "./pages/Audition";
import Result from "./pages/Result";
import Winners from "./pages/Winners";
import Privacy from "./pages/Privacy"
import Admin from "./pages/Admin";
import Resetpwd from "./pages/Password";
import AuthContextProvider from "./context/authContext";
import Game from "./pages/Game";
import DW from "./pages/DW";
import Maintenance from "./pages/Maintenance";
import Cafe from "./components/Cafe";
import ChessGame from "./pages/ChessGame";
import RaceResults from "./components/RaceResults";
import Loka from "./pages/Loka";
import VoiceAnalysis from "./components/VoiceAnalysis";
import Approve from "./pages/Approve";
import RedeemPage from "./pages/RedeemPage";
import Helpbox from "./pages/Helpbox";

export const routes = [
  {
    path: `${process.env.PUBLIC_URL}/bd`,
    element: <Comingsoon />,
  },

  {
    path: `${process.env.PUBLIC_URL}/`,
    layout:'default',
    element: (
      <PostsProvider>
        <Home />
      </PostsProvider>
    ),
    //isProtected:true
  },
  {
    path: `${process.env.PUBLIC_URL}/boatrace`,
    layout:'default',
    element: (
      <AuthContextProvider>
        <BoatRace />
      </AuthContextProvider>
    ),
    isProtected:true
  },
  {
    path: `${process.env.PUBLIC_URL}/redeem`,
    layout:'default',
    element: (
      <AuthContextProvider>
        <RedeemPage />
      </AuthContextProvider>
    ),
    isProtected:true
  },
  {
    path: `${process.env.PUBLIC_URL}/approve`,
    layout:'default',
    element: (
      <AuthContextProvider>
        <Approve />
      </AuthContextProvider>
    ),
    isProtected:true
  },
  {
    path: `${process.env.PUBLIC_URL}/va`,
    layout:'default',
    element: (
      <AuthContextProvider>
        <VoiceAnalysis />
      </AuthContextProvider>
    ),
    isProtected:true
  },
  {
    path: `${process.env.PUBLIC_URL}/cafe`,
    layout:'default',
    element: (
      <AuthContextProvider>
        <Cafe />
      </AuthContextProvider>
    ),
    isProtected:true
  },
  {
    path: `${process.env.PUBLIC_URL}/comments/`,
    layout:'default',
    element: (
      <PostsProvider>
        <PostComments />
      </PostsProvider>
    ),
  },
  {
    path: `${process.env.PUBLIC_URL}/buzz`,
    layout:'default',
    element: (
      <PostsProvider>
        <Moments />
      </PostsProvider>
    ),
    isProtected:true
  },
  {
    path: `${process.env.PUBLIC_URL}/compose`,
    layout:'default',
    element: (
      <AuthContextProvider>
        <Composition />
      </AuthContextProvider>
    )
  },
  {
    path: `${process.env.PUBLIC_URL}/chess`,
    layout:'default',
    element: (
      <AuthContextProvider>
        <ChessGame />
      </AuthContextProvider>
    ),
    isProtected:true
  },
  {
    path: `${process.env.PUBLIC_URL}/loka/:room`,
    layout:'default',
    element: (
      <AuthContextProvider>
        <Loka />
      </AuthContextProvider>
    )
  },
  {
    path: `${process.env.PUBLIC_URL}/gupshup/:room`,
    layout:'default',
    element: (
      <AuthContextProvider>
        <GupShup />
      </AuthContextProvider>
    )
  },
  {
    path: `${process.env.PUBLIC_URL}/game`,
    layout:'default',
    element: (
      <AuthContextProvider>
        <Game />
      </AuthContextProvider>
    ),
    isProtected:true
  },
  {
    path: `${process.env.PUBLIC_URL}/userpage`,
    layout:'default',
    element: (
      <PostsProvider>
        <Userpage />
      </PostsProvider>
    ),
   isProtected:true
  },
  {
    path: `${process.env.PUBLIC_URL}/home`,
    layout:'default',
    element: (
      <PostsProvider>
        <Start />
      </PostsProvider>
    ),
    isProtected:true
  },
  {
    path: `${process.env.PUBLIC_URL}/moments`,
    layout:'default',
    element: (
      <PostsProvider>
        <Moments />
      </PostsProvider>
    ),
  },
  {
    path: `${process.env.PUBLIC_URL}/judge`,
    layout:'default',
    element: (
      <EventProvider>
        <Judge />
      </EventProvider>
    ),
  },
  {
    path: `${process.env.PUBLIC_URL}/audition`,
    layout:'default',
    element: (
      <EventProvider>
        <Audition />
      </EventProvider>
    ),
  },
  {
    path: `${process.env.PUBLIC_URL}/participants`,
    layout:'default',
    element: (
      <EventProvider>
        <Participants />
      </EventProvider>
    ),
  },
  {
    path: `${process.env.PUBLIC_URL}/certificates`,
    layout:'default',
    element: (
      <EventProvider>
        <Certificate />
      </EventProvider>
    ),
  },
  {
    path: `${process.env.PUBLIC_URL}/sponsor`,
    element: <Sponsor />,
  },
  {
    path: `${process.env.PUBLIC_URL}/denied`,
    element: <Denied />,
  },
  {
    path: `${process.env.PUBLIC_URL}/fs`,
    element: <FreeStyle />,
  },
    {
    path: `${process.env.PUBLIC_URL}/dw`,
    element: (
      <AuthContextProvider>
        <DW />
      </AuthContextProvider>
    ),
    isProtected:true
  },
  {
    path: `${process.env.PUBLIC_URL}/income`,
    element: <Income />,
  },
  {
    path: `${process.env.PUBLIC_URL}/raceresult/:rtype`,
    element: <RaceResults />,
  },

  {
    path: `${process.env.PUBLIC_URL}/defaultbadge`,
    element: <Badge />,
  },
  {
    path: `${process.env.PUBLIC_URL}/result`,
    element: <Result />,
  },
  {
    path: `${process.env.PUBLIC_URL}/room`,
    element: <VoiceChatRoom />,
  }, 
  {
    path: `${process.env.PUBLIC_URL}/maintenance`,
    element: <Maintenance />,
  },
  {
    path: `${process.env.PUBLIC_URL}/nominate`,
    element: <Nominate />,
  },
  {
    path: `${process.env.PUBLIC_URL}/events`,
    element: <Events />,
  },
  {
    path: `${process.env.PUBLIC_URL}/admin`,
    element: <Admin />,
  },
 
  {
    path: `${process.env.PUBLIC_URL}/planets`,
    element: <Grps />,
  },
 
  {
    path: `${process.env.PUBLIC_URL}/winners`,
    element: <Winners />,
  },
  
  {
    path: `${process.env.PUBLIC_URL}/defaultemailbox`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/defaultemailopen`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/defaultsettings`,
    element: <Settings />,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/defaultvideo`,
  //   element: <Videos />,
  // },
  {
    path: `${process.env.PUBLIC_URL}/defaultanalytics`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/accountinformation`,
    element: <Account />,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/search`,
  //   element: <Member />,
  // },
    {
    path: `${process.env.PUBLIC_URL}/search`,
    element: (
      <AuthContextProvider>
        <Member />
      </AuthContextProvider>
    ),
    isProtected:true
  },
  {
    path: `${process.env.PUBLIC_URL}/resetpwd`,
    element: <Resetpwd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/contactinformation`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/socialaccount`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/password`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/payment`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/notification`,
    element: <Notification />,
  },
  
  {
    path: `${process.env.PUBLIC_URL}/helpbox`,
    element: <Helpbox />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forgot-password`,
    element: <Forgot />,
    layout:'auth', 
  },
  {
    path: `${process.env.PUBLIC_URL}/login`,
    element: <Login />,
    layout:'auth', 
  },
  {
    path: `${process.env.PUBLIC_URL}/register`,
    element: <Register />,
    layout:'auth', 
    
  },
  {
    path: `${process.env.PUBLIC_URL}/forgot`,
    element: <Comingsoon />,
    layout:'default'
  },
  {
    path: `${process.env.PUBLIC_URL}/notfound`,
    element: <Notfound />,
  },

  {
    path: `${process.env.PUBLIC_URL}/checkout`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/defaultmessage`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/defaultlive`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/`,
    element: <Home />,
    layout:'default',
  },
  {
    path: `${process.env.PUBLIC_URL}/defaultlive`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/defaultjob`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/defaultevent`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/defaulthotel`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/grouppage`,
    element: <Comingsoon />,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/userpage`,
  //   element: <Userpage />,
  // },
  {
    path: `${process.env.PUBLIC_URL}/authorpage`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/comingsoon`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/defaulthoteldetails`,
    element: <Comingsoon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/terms`,
    element: <Terms />,
    layout: 'auth'
  },
  {
    path: `${process.env.PUBLIC_URL}/privacy`,
    element: <Privacy />,
    layout: 'auth'
  },
  {
    path: `${process.env.PUBLIC_URL}/community`,
    element: <Community />,
    layout: 'auth'
  },
  {
    path: `${process.env.PUBLIC_URL}/chatlist`,
    element: (
        <ChatInbox />
    ),
    isProtected:true
  },
  {
    path: `${process.env.PUBLIC_URL}/chat`,
    element: (
      <SocketContextProvider>
        <Chat />
      </SocketContextProvider>
    ),
    isProtected:true
  },

];
